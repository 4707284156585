<template>
  <section id="approvals">
    <div id="inside">
      <h1>Produkty, na których pracujemy</h1>
      <h3>
        &emsp;Natrysk pianki PUR to izolacja produkowana na placu budowy, za
        pomocą specjalistycznego sprzętu przez wykwalifikowanych pracowników.
        Polecamy izolacje wykonane na produktach:
      </h3>
      <div id="productList">
        <div
          class="product"
          v-for="product in products"
          :key="product.foamName"
          v-on:click="$router.push(product.foamPath)"
        >
          <div class="flippety">
            <img src="../assets/images/pistol.png" />
            <h1>{{ product.foamName }}</h1>
          </div>
          <div class="flop">
            <h1>{{ product.foamName }}</h1>
            <h2>{{ product.foamDesc }}</h2>
            <h3>Czytaj więcej</h3>
          </div>
        </div>
      </div>
      <h3>
        W oparciu o Deklaracje zgodności CE, możemy porównać używane przez nas
        materiały:
      </h3>
      <table>
        <thead>
          <tr>
            <th>Właściwości użytkowe</th>
            <th>QuadFoam 500 / SealTITE PRO</th>
            <th>IQ Foam+</th>
            <th>IQ Foam+ FR</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Przewodność cieplna λ</td>
            <td>0,038 W/mK</td>
            <td>0,037 W/mK</td>
            <td>0,037 W/mK</td>
          </tr>
          <tr>
            <td>Reakcja na ogień</td>
            <td>E</td>
            <td>F</td>
            <td>E</td>
          </tr>
          <tr>
            <td>Reakcja na ogień, w przegrodzie z płytą GK</td>
            <td>B-s1, d0</td>
            <td>B-s1, d0</td>
            <td>B-s1, d0</td>
          </tr>
          <tr>
            <td>Współczynnik oporu dyfuzyjnego</td>
            <td>3,5</td>
            <td>4,45</td>
            <td>4,45</td>
          </tr>
          <tr>
            <td>Gwarancja producenta</td>
            <td>25 lat</td>
            <td>25 lata</td>
            <td>25 lata</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      products: [
        {
          foamName: "SealTITE PRO",
          foamDesc: "Amerykańska pianka otwartokomórkowa klasa premium",
          foamPath: "/produkty/sealtite-pro",
        },
        {
          foamName: "IQ Foam+",
          foamDesc: "Niemiecka pianka otwartokomórkowa",
          foamPath: "/produkty/iq-foam-plus",
        },
        {
          foamName: "IQ Foam+ FR",
          foamDesc:
            "Niemiecka pianka otwartokomórkowa o zwiększonej klase palności",
          foamPath: "/produkty/iq-foam-plus-fr",
        },
      ],
    };
  },
};
</script>

<style scoped>
section#approvals {
  display: flex;
  flex-direction: column;
  align-items: center;
}

section#approvals div#inside {
  width: 60%;
  margin: 120px 0 40px 0;
}

section#approvals div#inside h1 {
  font-size: 1.4rem;
  color: #6ba421;
  margin: 60px 0 30px 0;
  font-weight: 600;
}

section#approvals div#inside h2 {
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0 0 30px 0;
  font-weight: 600;
  width: 70%;
}

section#approvals div#inside h3 {
  font-size: 1rem;
  margin: 0 0 30px 0;
  width: 70%;
  line-height: 1.2rem;
  text-align: justify;
}

section#approvals div#inside h3 b {
  font-weight: 600;
}

section#approvals div#inside h4 {
  font-size: 1rem;
  margin: 0 0 30px 0;
  color: #6ba421;
  width: 70%;
  font-weight: 600;
  line-height: 1.2rem;
}

section#approvals div#inside a {
  font-size: 1rem;
  margin: 0 0 30px 0;
  color: #6ba421;
  width: 70%;
  font-weight: 600;
  line-height: 1.2rem;
  text-decoration: none;
  transition: 0.3s ease;
}

section#approvals div#inside a:hover {
  color: #5a8310;
}

section#approvals div#inside table,
th,
td {
  border: 1px solid black;
  text-align: center;
  padding: 10px;
}

section#approvals div#inside table {
  width: 70%;
  margin: 0 0 60px 0;
}

section#approvals div#inside tr {
  width: 100%;
}

section#approvals div#inside th {
  font-weight: 600;
  width: 25%;
}

section#approvals div#inside div#productList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
}

section#approvals div#inside div#productList div.product {
  width: 160px;
  text-align: center;
  height: 160px;
  transition: transform 0.3s;
  transform-style: preserve-3d;
  cursor: pointer;
  margin: 0 20px 40px 0;
  z-index: 1;
}

section#approvals div#inside div#productList div.product .flippety,
.flop {
  background-color: #f0f0f0;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
}

section#approvals div#inside div#productList div.product .flippety h1 {
  font-size: 20px;
  color: #000;
  margin: 0 0 5px 0;
  font-weight: bold;
}

section#approvals div#inside div#productList div.product .flippety img {
  width: 100px;
}

section#approvals div#inside div#productList div.product .flop h1 {
  font-size: 20px;
  color: #fff;
  margin: 0;
  font-weight: bold;
  text-decoration: underline;
}

section#approvals div#inside div#productList div.product .flop h2 {
  font-size: 14px;
  color: #fff;
  margin: 0;
  line-height: normal;
  font-weight: normal;
  width: 100%;
}

section#approvals div#inside div#productList div.product .flop h3 {
  font-size: 16px;
  color: #fff;
  margin: 0;
  width: 100%;
  text-align: center;
}

section#approvals div#inside div#productList div.product .flop {
  background-color: #8dc643;
  color: #fff;
  justify-content: space-around;
}

@media only screen and (min-width: 1280px) {
  section#approvals div#inside div#productList div.product .flippety {
    transform: translateZ(80px);
    z-index: 1;
  }

  section#approvals div#inside div#productList div.product .flop {
    transform: rotateX(-90deg) translateZ(-80px);
    z-index: 1;
  }

  section#approvals div#inside div#productList div.product:hover {
    transform: rotateX(90deg);
    z-index: 1;
  }
}

@media only screen and (max-width: 1280px) {
  section#approvals div#inside {
    width: 80%;
    margin: 60px 0 0 0;
  }

  section#approvals div#inside h2 {
    width: 100%;
  }

  section#approvals div#inside h3 {
    width: 100%;
  }

  section#approvals div#inside div#productList {
    justify-content: center;
  }

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  section#approvals div#inside div#productList div.product {
    transform-style: flat;
    z-index: 1;
  }

  section#approvals div#inside div#productList div.product .flop {
    display: none;
  }

  section#approvals div#inside table {
    width: 100%;
    border: none;
    font-size: 0.8rem;
    margin: 0 0 60px -1px;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: wrap;
  }

  td:nth-of-type(1):before {
    content: "Właściwości użytkowe";
  }
  td:nth-of-type(2):before {
    content: "QuadFoam 500 / SealTITE PRO";
  }
  td:nth-of-type(3):before {
    content: "IQ Foam+";
  }
  td:nth-of-type(4):before {
    content: "IQ Foam+ FR";
  }
}
</style>
